import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import './App.css';
import Dashboard from './components/Dashboard';
import LandingPage from './components/LandingPage';
import Login from './components/Login';
import { AuthProvider, useAuth } from './context/AuthContext';

// Protected Route wrapper
const PrivateRoute = ({ children }) => {
  const { user } = useAuth();
  return user ? children : <Navigate to="/" />;
};

function App() {
  const [apiStatus, setApiStatus] = useState('loading'); // 'loading', 'success', 'error'
  const [error, setError] = useState(null);

  useEffect(() => {
    fetch('/api/test')
      .then(response => {
        if (!response.ok) {
          throw new Error('API not available');
        }
        return response.json();
      })
      .then(data => {
        console.log('API Response:', data);  // Add this for debugging
        setApiStatus('success');
      })
      .catch(err => {
        console.error('API Error:', err);  // Add this for debugging
        setError(err.message);
        setApiStatus('error');
      });
  }, []);

  return (
    <AuthProvider>
      <Router>
        <div className="App">
          {error && <p className="error-message">Error: {error}</p>}
          {apiStatus === 'loading' ? (
            <div className="App-header">
              <h1>Gothos.biz</h1>
              <p>Loading API...</p>
            </div>
          ) : (
            <Routes>
              <Route path="/" element={<LandingPage />} />
              <Route path="/login" element={<Login />} />
              <Route
                path="/dashboard"
                element={
                  <PrivateRoute>
                    <Dashboard />
                  </PrivateRoute>
                }
              />
            </Routes>
          )}
        </div>
      </Router>
    </AuthProvider>
  );
}

export default App;