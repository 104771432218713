import React from 'react';

const LandingPage = () => {
  const handleBuyGothos = async () => {
    try {
      const response = await fetch('https://gothos.biz/api/init-checkout', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' }
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      if (data.url) {
        window.location.href = data.url;
      } else {
        throw new Error('No checkout URL received');
      }
    } catch (error) {
      console.error('Checkout error:', error);
    }
  };

  return (
    <div className="min-h-screen flex flex-col items-center justify-center p-4">
      <div className="text-center">
        <h1 className="text-4xl font-bold mb-4">Welcome to Gothos</h1>
        <button
          onClick={handleBuyGothos}
          className="bg-purple-600 text-white px-6 py-3 rounded-lg"
        >
          Buy Gothos
        </button>
      </div>
    </div>
  );
};

export default LandingPage;