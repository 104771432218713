import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthContext from "../context/AuthContext";

const Dashboard = () => {
  const { user, logout } = useContext(AuthContext);
  const [balance, setBalance] = useState(null);
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState(null);
  const [passwordChangeSuccess, setPasswordChangeSuccess] = useState(false);
  const navigate = useNavigate();

  const convertToGothos = (dollarAmount) => {
    return dollarAmount ? (dollarAmount / 0.5) : 0;
  };

  useEffect(() => {
    if (user?.wallet_address) {
      fetchBalance();
    }
  }, [user]);

  const fetchBalance = async () => {
    try {
      const response = await fetch(`/api/get_balance/${user.wallet_address}`);

      if (!response.ok) {
        console.error('Error response:', response.status);
        return;
      }

      const data = await response.json();
      setBalance(convertToGothos(data.balance));
    } catch (error) {
      console.error('Error fetching balance:', error);
    }
  };

  const handlePasswordChange = async () => {
    if (newPassword !== confirmPassword) {
      setError("Passwords don't match");
      return;
    }

    if (newPassword.length < 8) {
      setError("Password must be at least 8 characters long");
      return;
    }

    try {
      const response = await fetch('/api/change_password', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify({ new_password: newPassword })
      });

      if (!response.ok) {
        throw new Error('Failed to change password');
      }

      setPasswordChangeSuccess(true);
      setNewPassword('');
      setConfirmPassword('');
      setError(null);

      // Logout after 3 seconds and redirect to login
      setTimeout(() => {
        logout();
        navigate('/login');
      }, 3000);

    } catch (error) {
      setError('Failed to change password. Please try again.');
    }
  };

  if (!user) {
    return <div className="flex justify-center items-center min-h-screen">Loading...</div>;
  }

  return (
    <div className="min-h-screen p-8">
      <div className="max-w-4xl mx-auto">
        <div className="bg-white rounded-lg shadow-lg p-6">
          <h1 className="text-2xl font-bold mb-4">Welcome, {user.username}!</h1>
          <p className="text-gray-600 mb-4">Wallet: {user.wallet_address}</p>

          {balance !== null ? (
            <div className="mb-6">
              <p className="text-xl">Balance: {balance.toFixed(2)} GOTHOS</p>
              <p className="text-sm text-gray-500">(${(balance * 0.5).toFixed(2)} USD)</p>
            </div>
          ) : (
            <p>Loading balance...</p>
          )}

          {user.should_change_password && (
            <div className="mt-6 p-4 bg-yellow-50 rounded-lg">
              <h2 className="text-lg font-semibold mb-4">Change Temporary Password</h2>
              {error && (
                <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mb-4">
                  {error}
                </div>
              )}
              {passwordChangeSuccess && (
                <div className="bg-green-100 border-l-4 border-green-500 text-green-700 p-4 mb-4">
                  Password changed successfully! Redirecting to login...
                </div>
              )}
              <input
                type="password"
                placeholder="New Password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                className="w-full p-2 border rounded mb-2"
              />
              <input
                type="password"
                placeholder="Confirm New Password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                className="w-full p-2 border rounded mb-4"
              />
              <button
                onClick={handlePasswordChange}
                className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 mr-4"
              >
                Change Password
              </button>
            </div>
          )}

          <button
            onClick={logout}
            className="bg-red-500 text-white px-4 py-2 rounded mt-4 hover:bg-red-600"
          >
            Logout
          </button>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;